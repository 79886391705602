import React, { useContext, useEffect } from 'react'
import HomeMain from '../components/home/home_main'
import UserContext from '../components/UserContext'
import { CelebritiesService } from '../services/celebrities.service'
import { QuotesService } from '../services/quotes.service'

export default function Home({ celebrities, featuredCelebrities, quotes }) {
  const { scrollRef } = useContext(UserContext)

  useEffect(() => {
    window.scrollTo(0, scrollRef.current.scrollPos)
    const handleScrollPos = () => {
      scrollRef.current.scrollPos = window.scrollY
    }
    window.addEventListener('scroll', handleScrollPos)
    return () => {
      window.removeEventListener('scroll', handleScrollPos)
    }
  })

  return (
    <>
      <HomeMain
        celebrities={celebrities.celebrities}
        featuredCelebrities={featuredCelebrities}
        quotes={quotes.quotes}
      />
    </>
  )
}

export const getStaticProps = async () => {
  try {
    const { data: celebrities } = await CelebritiesService.getCelebrities({
      sort: 'newest',
      perPage: '6',
    })

    const { data: featuredCelebrities } = await CelebritiesService.getFeaturedCelebrities({
      perPage: '8',
      page: '1',
    })

    const { data: quotes } = await QuotesService.getQuotes({ perPage: '4' })

    return { props: { celebrities, featuredCelebrities, quotes } }
  } catch (error) {
    return { props: {} }
  }
}
